<template>
  <div class="flex flex-col justify-center h-full">
    <div
      class="flex justify-center items-center h-[68px] sticky top-0 z-10 md:static md:z-0 border-b w-full"
      :class="{
        'bg-neutral-white': !$attrs['header-bg-color'],
      }"
      :style="{
        backgroundColor: $attrs['header-bg-color'] ? $attrs['header-bg-color'] : null,
        borderColor: $attrs['header-bg-color'] ? $attrs['header-bg-color'] : null,
      }"
    >
      <div v-if="!!$attrs.logo">
        <img :src="$attrs.logo" alt="Brand Logo" class="max-h-12" />
      </div>
      <PulseIconLogo v-else name="logo" />
    </div>
    <div
      class="flex-1 flex md:items-center justify-center px-2 md:px-12"
      :class="{ 'max-h-screen h-20': !!$attrs.print }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
useHead({
  title: 'OrderProtection.com',
});
</script>
